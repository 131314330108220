/*
*   date - string
*   title - string
*   link - URL string
*   materials - [{ 
*       type - icon
*       link - URL string
*   }]
*   descriptions - [string]
*   skills - [
*       [string] - 1 array/line
*   ]
*   picture - file path
*/

// import { faBook } from "@fortawesome/free-solid-svg-icons";

import sportAnalyzer from "./../asset/sportanalyzer.webp"

export const data = [
    {
        date: "2023-Present",
        title: "Fullstack · Sport Analysis System",
        link: "https://medium.com/@KDeelert/how-i-built-soccer-analyzer-tool-eng-55bf8a473248",
        materials: [
            // { type: faBook, link: "https://medium.com/@KDeelert/how-i-built-soccer-analyzer-tool-eng-55bf8a473248" },
        ],
        descriptions: [
            "· Developed a web scraping bot to extract real-time odds and various statistics for soccer matches.",
            "· Developed and implemented real-time statistical analysis and automated betting systems",
            "· Designed and implemented a customizable condition system to identify betting opportunities.",
            "· Designed and implemented a responsive web application to display real-time statistics.",
            "· Utilized load balancing and reverse proxy to ensure smooth system performance.",
            "· Developed a notification system to send alerts to the Line application when betting conditions are met.",
        ],
        skills: [
            ["React", "Redux-Saga", "Antd"],
            ["Playwright", "Nginx", "NodeJS", "MongoDB"]
        ],
        picture: sportAnalyzer,
    },
    {
        date: "2013-2018",
        title: "Police Officer",
        link: "",
        materials: [
        ],
        descriptions: [],
        skills: [],
        picture: "",
    },
    {
        date: "2012-2012",
        title: "Middleware System Engineer · IBM",
        link: "",
        materials: [
        ],
        descriptions: [
            "· Implemented and on-going support Middleware infrastructure for internal project.",
        ],
        skills: [
            ["IBM Websphere", "Linux"],
        ],
        picture: "",
    },
];